import './App.css';
import { BrowserRouter as Router,Route,Routes, BrowserRouter} from 'react-router-dom';
import Home from './Pages/Home';
import DigitalMarketing from './Components/DigitalMakreting';
import Ventures from './Components/Ventures';
import Content2 from './Components/Content2';
import ContactUs from './Pages/ContactUs';
import AllServices from './Components/AllServices';

function App() {
  return (
    <>

<Router>
<Routes>
  <Route exact path='/' element={<Home/>}/>
  <Route exact path='/home' element={<Home/>}/>
  <Route exact path='/allservices' element={<AllServices/>}/>
  <Route exact path='/DigitalMarketing' element={<DigitalMarketing/>}/>
  <Route exact path='/Ventures' element={<Ventures/>}/>
  <Route exact path='/Contact' element={<ContactUs/>}/>
</Routes>
  </Router>

    </>
  );
}
export default App;
