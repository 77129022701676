import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';
import CustomNavbar from '../Components/Navbar'
import Banner from '../Components/Banner'
import Content1 from '../Components/Content1'
import Content2 from '../Components/Content2'
import './Home.css'
// import Content3 from '../Components/Content3'
import Content4 from '../Components/Content4'
import Content5 from '../Components/Content5'
import Footer from '../Components/Footer'
import Content02 from '../Components/Content02'
function Home() {
    const location = useLocation();
  
    useEffect(() => {
      if (location.state?.targetId) {
        scroller.scrollTo(location.state.targetId, {
          smooth: true,
          duration: 500,
          offset: -70,
        });
      }
    }, [location]);
  
  return (
    <>
    <section> </section>
      <CustomNavbar></CustomNavbar>
    <Banner></Banner>
    <Content1></Content1>
    <section id='about' >
    <Content02/>
    </section>
    <section id='services'>
    <Content2/>
    </section>
    
    {/* <Content3></Content3> */}
    <Content4></Content4>
    {/* <Content5></Content5> */}
    <Footer></Footer>
    </>
  )
}

export default Home
