import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './Content2.css';

function Content2() {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/allservices'); // Updated path to navigate to '/allservices'
  };

  return (
    <Container fluid>
      {/* First Section */}
      <Row className="align-items-center custom-section">
        <Col xs={12} md={12} className="text-center">
          <h2 className="section-heading">Our Services</h2>
        </Col>
      </Row>
      
      {/* Second Section */}
      <Row className="align-items-center custom-section no-padding">
        <Col xs={12} md={6} className="text-section">
          <h2>IT Development & Services</h2>
          <Button variant="primary" onClick={handleNavigate}>Learn More</Button>
        </Col>
        <Col xs={12} md={6} className="image-col no-padding">
          <img src="/images/image1.jpg" alt="Image 1" className="full-width-image" />
        </Col>
      </Row>

      {/* Third Section */}
      <Row className="align-items-center custom-section no-padding">
        <Col xs={12} md={6} className="image-col no-padding">
          <img src="/images/image2.jpg" alt="Image 2" className="full-width-image" />
        </Col>
        <Col xs={12} md={6} className="text-section">
          <h2>School Services</h2>
          <Button variant="primary" onClick={handleNavigate}>Learn More</Button>
        </Col>
      </Row>

      {/* Fourth Section */}
      <Row className="align-items-center custom-section no-padding">
        <Col xs={12} md={6} className="text-section">
          <h2>College Services</h2>
          <Button variant="primary" onClick={handleNavigate}>Learn More</Button>
        </Col>
        <Col xs={12} md={6} className="image-col no-padding">
          <img src="/images/image3.jpg" alt="Image 3" className="full-width-image" />
        </Col>
      </Row>
    </Container>
  );
}

export default Content2;
