import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaFacebookF, FaInstagram, FaYoutube } from 'react-icons/fa';

const DMSocialmedia = () => {
  return (
    <div style={{ position: 'fixed', top: '20%', left: '0', transform: 'translateY(-50%)' }}>
      <div className="d-flex flex-column align-items-center p-2" style={{ backgroundColor: '#f8f9fa', borderRadius: '0 8px 8px 0', boxShadow: '0 0 5px rgba(0,0,0,0.3)' }}>
        {/* Instagram Icon */}
        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="text-dark mb-3" style={{ fontSize: '24px' }}>
          <FaInstagram />
        </a>
        {/* Facebook Icon */}
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="text-dark mb-3" style={{ fontSize: '24px' }}>
          <FaFacebookF />
        </a>
        {/* YouTube Icon */}
        <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer" className="text-dark" style={{ fontSize: '24px' }}>
          <FaYoutube />
        </a>
      </div>
    </div>
  );
};

export default DMSocialmedia;
